<template>
	<div class="p-4 lg:p-6 pt-0 lg:pt-6 h-full">
		<div class="flex justify-between items-center pb-4">									
			<div class="text-2xl font-bold">{{ route.name == "Provider_Inventory" ? "Inventory" : "Adjust" }}</div>								
		</div>

		<div class="w-full bg-base-100">      

			<div class="overflow-x-auto">
				<table v-if="!inventory" class="table">            
					<thead>
						<tr>								
							<th class="pl-20">Product</th>														
							<th >
								<div class="skeleton w-12 h-5"></div>	
							</th>								
						</tr>
					</thead>
					<tbody>              
						<tr class="">   
							<td>
								<div class="skeleton w-12 h-5"></div>						
							</td>			
							<td>
								<div class="skeleton w-12 h-5"></div>
							</td>						
						</tr>
					</tbody>                               
				</table>

				<table v-if="inventory && inventory.length > 0  && locations && locations.length > 0" class="table">            
					<thead>
						<tr>								
							<th class="pl-20">Product</th>								
							<th v-for="location in locations" v-bind:key="location.id">{{location.name}}</th>            						
							<th v-if="type == 'stock'">Total</th>								
						</tr>
					</thead>
					<tbody>              
						<tr v-for="product in inventory" v-bind:key="product.product_id">                        
							<td>									
								<div class="flex items-center space-x-4">
									<div class="rounded-lg overflow-hidden w-12 h-12 bg-gray-300">
										<img :src=" product.product_photo || ''"/>
									</div>
									<div>
										{{product.product_name}}
									</div>
								</div>
							</td>													
							<td v-for="location in locations" v-bind:key="location.id">
								<input v-if="type == 'adjust'" v-model="product.adjust[location.id]" type="number" placeholder="0" class="input input-sm input-bordered" />
								<div v-if="type == 'stock'"> {{product.stock[location.id]}}</div>
							</td> 
							<td v-if="type == 'stock'">
								{{product.total_stock}}
							</td>           						           
						</tr>
					</tbody>                               
				</table>

			</div>
		</div>

		<div v-if="inventory && inventory.length > 0 && locations && locations.length > 0 && type == 'adjust'" class="pt-6 flex-none">
			<button @click="handler()" class="btn btn-success btn-sm w-full text-white no-animation">Adjust inventory</button>
		</div>

		<div class="card-bordered card-compact rounded-lg shrink-0 w-full bg-base-100" v-if="(inventory && inventory.length == 0) || (locations && locations.length == 0)">     
			<div class="card-body text-center flex flex-row justify-center items-center align-center space-x-0">
				Add your first<router-link class="underline" :to=" `/provider/${route.params.provider_id}/products` ">product</router-link>and<router-link class="underline" :to=" `/provider/${route.params.provider_id}/locations` ">location</router-link>to begin tracking inventory. 
			</div>
		</div>

	</div>
</template>

<script>	
	import { onMounted, ref, computed, watch } from "vue"
	import axios from "@/axios.js"
	import { useRoute, useRouter } from 'vue-router'
	import { useToast } from 'vue-toast-notification';
	import { useLoading } from 'vue-loading-overlay'

	export default {
		name: "Provider_Inventory",			
		components: {
			
		},
		setup() {			
			const route = useRoute()
			const router = useRouter()					

			const inventory = ref(null)
			const locations = ref(null)
			
			const loading = useLoading({
				color: "#4793AF",
				loader: "spinner"
			});
			
			onMounted( async () => {                
				axios.get(`/api/provider/${route.params.provider_id}/inventory`)
				.then( res => { 

					inventory.value = transformInventory(res.data.inventory)
					console.log(	inventory.value)
				})
				.catch(e => {
					console.log(e)
				}) 

				axios.get(`/api/provider/${route.params.provider_id}/locations`)
				.then( res => { 					
					locations.value = res.data.locations
				})
				.catch(e => {
					console.log(e)
				}) 

				
			})		

			const type = computed( () =>{
				if (route.name == "Provider_Adjust") {
					return 'adjust'
				} 

				return 'stock'
			})

			function transformInventory(inventory) {
				const productMap = {};

				inventory.forEach(item => {
					const { product_id, product_name, product_photo, location_id, current_stock, total_stock } = item;

					if (!productMap[product_id]) {
						productMap[product_id] = {
							product_id,
							product_name,
							product_photo,
							total_stock,
							stock: {},
							adjust: {}
						};
					}

					productMap[product_id].stock[location_id] = current_stock
					productMap[product_id].adjust[location_id] = current_stock;
				});


				return Object.values(productMap);
			}

			function findStockAdjustDifferences(inventory) {
				const locationMap = {};

				inventory.forEach(product => {
					Object.keys(product.stock).forEach(locationId => {
						const stockValue = product.stock[locationId];
						const adjustValue = product.adjust[locationId];

						if (stockValue !== adjustValue) {        
							if (!locationMap[locationId]) {
								locationMap[locationId] = {
									locationId: parseInt(locationId, 10),
									providerId: route.params.provider_id,
									transaction_type: "ADJUST",
									products: []
								};
							}

							locationMap[locationId].products.push({
								id: product.product_id,								
								quantity: adjustValue - stockValue
							});
						}
					});
				});

				return Object.values(locationMap);
			}

			watch(type, async (oldType, newType) => {
				console.log(oldType, newType)

				let loader = loading.show()

				axios.get(`/api/provider/${route.params.provider_id}/inventory`)
				.then( res => { 
					inventory.value = transformInventory(res.data.inventory)
					console.log(inventory.value)
					loader.hide()		
				})
				.catch(e => {
					loader.hide()		
					console.log(e)
				}) 

			})

			async function createOrder(locations) {	
				let loader = loading.show()

				for (const location of locations) {
					const result = await axios.post('/api/order', { order: JSON.stringify(location) })
					console.log('Result:', result);
				}

				useToast().success('The adjustment has been created.', { position: "top-right" });
				loader.hide()						
			}

			function handler() {
				if (!confirm('Are you sure you want to adjust the inventory?')) {
					return
				} 

				let adjusts = findStockAdjustDifferences(inventory.value)

				if (adjusts.length == 0) {
					return alert ("There are no adjustments to be made.")
				}
				createOrder(adjusts)
			}

			function goTo(product) {
				router.push(`/provider/${route.params.provider_id}/product/${product.id}`)				
			}

			return {
				route,
				type,
				handler,
				inventory,				
				locations,
				goTo
			}
		}
	};
</script>
